import React from "react"
import SEO from "../components/seo"
import { FaEnvelope } from "react-icons/fa"

import "../scss/aboutus.scss"

import VideoImage from "../assets/img/youCloud/aboutUs/Video.png"
import cloudImage from "../assets/img/youCloud/aboutUs/Cloud.svg"
import visionImage from "../assets/img/youCloud/aboutUs/Vision.svg"
import positioningImage from "../assets/img/youCloud/aboutUs/award.svg"
import Photo1 from "../assets/img/youCloud/aboutUs/Photo1.svg"
import Photo2 from "../assets/img/youCloud/aboutUs/Photo2.svg"
import Photo3 from "../assets/img/youCloud/aboutUs/Photo3.svg"

const AboutUsPage = () => {
  return (
    <div>
      <SEO title="About Us" keywords={[`youpal`, `youpal group`]} />
      <section className="aboutUsPageSection">
        <div>
          <img src={VideoImage} />
        </div>
      </section>

      <section className="container contactsSection">
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <img src={cloudImage} />
            </div>
            <h2 className="text-gradient-blue">Into the Cloud</h2>
            <p>
              With Youcloud we assist you in creating your own digital
              infrastructure. We can reduce the inconsistency of your projects
              and mistake probabilities. Clouds constantly change shapes and
              never stay the same for long. We are ready to make the updates and
              changes, constantly renovating the cloud infrastructure. The
              process is easy and safe, keeping your data secure in the clouds.
            </p>
          </div>
          <div className="col">
            <div className="mb-3">
              <img src={visionImage} />
            </div>
            <h2 className="text-gradient-blue">Vision</h2>
            <p>
              For us, you being constantly informed regarding changes is vital.
              We are always improving and working on changes, yet we do not
              execute them unless you approve. With the cloud infrastructure
              becomes more complex, we are ready to add different logic to your
              infrastructure definition. We can grant you control over your
              infrastructure and resources. Share our vision of success via
              practical and proven approaches.
            </p>
          </div>
          <div className="col">
            <div className="mb-3">
              <img src={positioningImage} />
            </div>
            <h2 className="text-gradient-orange">Positioning</h2>
            <p>
              What we offer is not exclusive for the market, yet the method of
              execution is what makes us outstanding. With Youcloud you get to
              work with edge computing, multi-function cloud infrastructure and
              secure storage solutions. We can answer all your questions about
              market demands, operational models, by providing our solutions. We
              offer strategically aligned and time valued solutions for the long
              term efficiency of your workflow.
            </p>
          </div>
        </div>
      </section>

      <h2 className="internalNarrowCol peopleTitle gradiantHeader">
        Meet the people in the Clouds
      </h2>

      <section className="container contactsSection">
        <div className="row">
          <div className="col">
            <div className="people">
              <img src={Photo1} />
            </div>
            <h4>Floris</h4>
            <p>“Architect”</p>
          </div>
          <div className="col">
            <div className="people">
              <img src={Photo2} />
            </div>
            <h4>Ruben</h4>
            <p>“Janitor”</p>
          </div>
          <div className="col">
            <div className="people">
              <img src={Photo3} />
            </div>
            <h4>Alexandra</h4>
            <p>“Oversight”</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutUsPage
